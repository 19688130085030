import React from "react";
import type { UseFormReturn } from "react-hook-form";
import { FormProvider as Form } from "react-hook-form";

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  methods: UseFormReturn<any>;
  onSubmit?: VoidFunction;
};
export default function FormProvider({
  children,
  onSubmit,
  methods
}: Props) {
  return <Form {...methods} data-sentry-element="Form" data-sentry-component="FormProvider" data-sentry-source-file="form-provider.tsx">
      <form onSubmit={onSubmit}>{children}</form>
    </Form>;
}