"use client";

// eslint-disable-next-line import/no-named-as-default
import NProgress from "nprogress";
import React, { useEffect } from "react";
import StyledProgressBar from "./styles";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type PushStateInput = [data: any, unused: string, url?: string | URL | null | undefined];
export default function ProgressBar() {
  useEffect(() => {
    NProgress.configure({
      showSpinner: false
    });
    const handleAnchorClick = (event: MouseEvent) => {
      const targetUrl = (event.currentTarget as HTMLAnchorElement).href;
      const currentUrl = window.location.href;
      if (targetUrl !== currentUrl) {
        NProgress.start();
      }
    };
    const handleMutation = () => {
      const anchorElements: NodeListOf<HTMLAnchorElement> = document.querySelectorAll("a[href]");
      const filteredAnchors = Array.from(anchorElements).filter(element => {
        const href = element.getAttribute("href");
        return href && href.startsWith("/");
      });
      filteredAnchors.forEach(anchor => anchor.addEventListener("click", handleAnchorClick));
    };
    const mutationObserver = new MutationObserver(handleMutation);
    mutationObserver.observe(document, {
      childList: true,
      subtree: true
    });
    window.history.pushState = new Proxy(window.history.pushState, {
      apply: (target, thisArg, argArray: PushStateInput) => {
        NProgress.done();
        return target.apply(thisArg, argArray);
      }
    });
  });
  return <StyledProgressBar data-sentry-element="StyledProgressBar" data-sentry-component="ProgressBar" data-sentry-source-file="progress-bar.tsx" />;
}