"use client";

import React, { useCallback, useEffect, useMemo, useReducer } from "react";
import type { IUserFullPublic } from "../../../../models/user.model";
import { apiGet, apiPost } from "../../../utils/api.utils";
import type { ActionMapType, AuthStateType } from "../../types";
import { AuthContext } from "./auth-context";
import { setMockAuthorization } from "./utils";

// ----------------------------------------------------------------------

enum Types {
  INITIAL = "INITIAL",
  LOGIN = "LOGIN",
  REGISTER = "REGISTER",
  LOGOUT = "LOGOUT",
}
type Payload = {
  [Types.INITIAL]: {
    user: IUserFullPublic | null;
  };
  [Types.LOGIN]: {
    user: IUserFullPublic | null;
  };
  [Types.REGISTER]: {
    user: IUserFullPublic | null;
  };
  [Types.LOGOUT]: undefined;
};
type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  user: null,
  loading: true
};
const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      loading: false,
      user: action.payload.user
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      user: action.payload.user
    };
  }
  if (action.type === Types.REGISTER) {
    return {
      ...state,
      user: action.payload.user
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      user: null
    };
  }
  return state;
};

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};
export function AuthProvider({
  children
}: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const initialize = useCallback(async () => {
    try {
      setMockAuthorization();
      const user: IUserFullPublic = await apiGet("/auth/session", {});
      dispatch({
        type: Types.INITIAL,
        payload: {
          user: {
            ...user
          }
        }
      });
    } catch (_error) {
      dispatch({
        type: Types.INITIAL,
        payload: {
          user: null
        }
      });
    }
  }, []);
  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email: string, password: string) => {
    const data = {
      email,
      password
    };
    const user = await apiPost("/auth/login", {
      body: data
    });
    dispatch({
      type: Types.LOGIN,
      payload: {
        user: {
          ...user
        }
      }
    });
  }, []);

  // REGISTER
  const register = useCallback(async (email: string, fullName: string) => {
    const data = {
      email,
      fullName
    };
    await apiPost("/auth/register", {
      body: data
    });
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    await apiGet("/auth/logout", {});
    dispatch({
      type: Types.LOGOUT
    });
  }, []);

  // FORGOT PASSWORD
  const forgotPassword = useCallback(async (email: string) => {
    await apiGet("/auth/reset-password", {
      querystring: {
        email
      }
    });
  }, []);

  // NEW PASSWORD
  const newPassword = useCallback(async (token: string, password: string) => {
    const user = await apiPost("/auth/reset-password", {
      body: {
        password
      },
      headers: {
        authorization: `Bearer ${token}`
      }
    });
    dispatch({
      type: Types.LOGIN,
      payload: {
        user: {
          ...user
        }
      }
    });
  }, []);

  // ACTIVATION
  const activation = useCallback(async (token: string) => {
    const user = await apiPost("/auth/activation", {
      headers: {
        authorization: `Bearer ${token}`
      }
    });
    dispatch({
      type: Types.LOGIN,
      payload: {
        user: {
          ...user
        }
      }
    });
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? "authenticated" : "unauthenticated";
  const status = state.loading ? "loading" : checkAuthenticated;
  const memoizedValue = useMemo(() => ({
    user: state.user,
    method: "jwt",
    loading: status === "loading",
    authenticated: status === "authenticated",
    unauthenticated: status === "unauthenticated",
    mustVerify: !!state.user?.fa_mode && !state.user.has_verified_opt,
    //
    login,
    register,
    logout,
    forgotPassword,
    newPassword,
    activation,
    initialize
  }), [login, logout, register, forgotPassword, newPassword, activation, initialize, state.user, status]);
  return <AuthContext.Provider value={memoizedValue} data-sentry-element="unknown" data-sentry-component="AuthProvider" data-sentry-source-file="auth-provider.tsx">{children}</AuthContext.Provider>;
}