"use client";

import { domMax, LazyMotion, m } from "framer-motion";
import React from "react";

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};
export function MotionLazy({
  children
}: Props) {
  return <LazyMotion strict features={domMax} data-sentry-element="LazyMotion" data-sentry-component="MotionLazy" data-sentry-source-file="motion-lazy.tsx">
      <m.div style={{
      height: "100%"
    }} data-sentry-element="unknown" data-sentry-source-file="motion-lazy.tsx"> {children} </m.div>
    </LazyMotion>;
}