"use client";

import React from "react";
import { SplashScreen } from "../../../components/loading-screen";
import { AuthContext } from "./auth-context";
// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};
export function AuthConsumer({
  children
}: Props) {
  return <AuthContext.Consumer data-sentry-element="unknown" data-sentry-component="AuthConsumer" data-sentry-source-file="auth-consumer.tsx">{async auth => auth.loading ? <SplashScreen /> : <>{children}</>}</AuthContext.Consumer>;
}