"use client";

import type { EmotionCache, Options as OptionsOfCreateCache } from "@emotion/cache";
// eslint-disable-next-line no-duplicate-imports
import createCache from "@emotion/cache";
import { CacheProvider as DefaultCacheProvider } from "@emotion/react";
import { useServerInsertedHTML } from "next/navigation";
import * as React from "react";

// ----------------------------------------------------------------------

export type NextAppDirEmotionCacheProviderProps = {
  /** This is the options passed to createCache() from 'import createCache from "@emotion/cache"' */
  options: Omit<OptionsOfCreateCache, "insertionPoint">;
  /** By default <CacheProvider /> from 'import { CacheProvider } from "@emotion/react"' */
  CacheProvider?: (props: {
    value: EmotionCache;
    children: React.ReactNode;
  }) => React.JSX.Element | null;
  children: React.ReactNode;
};

// Adapted from https://github.com/garronej/tss-react/blob/main/src/next/appDir.tsx
export default function NextAppDirEmotionCacheProvider(props: NextAppDirEmotionCacheProviderProps) {
  const {
    options,
    CacheProvider = DefaultCacheProvider,
    children
  } = props;
  const [registry] = React.useState(() => {
    const cache = createCache(options);
    cache.compat = true;
    const prevInsert = cache.insert;
    let inserted: {
      name: string;
      isGlobal: boolean;
    }[] = [];
    // @ts-ignore
    cache.insert = (...args) => {
      const [selector, serialized] = args;
      if (cache.inserted[serialized.name] === undefined) {
        inserted.push({
          name: serialized.name,
          isGlobal: !selector
        });
      }
      return prevInsert(...args);
    };
    const flush = () => {
      const prevInserted = inserted;
      inserted = [];
      return prevInserted;
    };
    return {
      cache,
      flush
    };
  });
  useServerInsertedHTML(() => {
    const inserted = registry.flush();
    if (inserted.length === 0) {
      return null;
    }
    let styles = "";
    let dataEmotionAttribute = registry.cache.key;
    const globals: {
      name: string;
      style: string;
    }[] = [];
    inserted.forEach(({
      name,
      isGlobal
    }) => {
      const style = registry.cache.inserted[name];
      if (typeof style !== "boolean") {
        if (isGlobal) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          globals.push({
            name,
            style
          } as any);
        } else {
          styles += style;
          dataEmotionAttribute += ` ${name}`;
        }
      }
    });
    return <>
        {globals.map(({
        name,
        style
      }) => <style key={name} data-emotion={`${registry.cache.key}-global ${name}`}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: style
      }} />)}
        {styles && <style data-emotion={dataEmotionAttribute}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: styles
      }} />}
      </>;
  });
  return <CacheProvider value={registry.cache} data-sentry-element="CacheProvider" data-sentry-component="NextAppDirEmotionCacheProvider" data-sentry-source-file="next-emotion-cache.tsx">{children}</CacheProvider>;
}