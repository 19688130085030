"use client";

// i18n
import "shared/ui/locales/i18n";
import { QueryClientProvider } from "@tanstack/react-query";
import PlausibleProvider from "next-plausible";
import { AuthProvider } from "shared/ui/auth/context/jwt";
import { MotionLazy } from "shared/ui/components/animate/motion-lazy";
import ProgressBar from "shared/ui/components/progress-bar";
import { SettingsProvider } from "shared/ui/components/settings";
import SnackbarProvider from "shared/ui/components/snackbar/snackbar-provider";
import { publicConfig } from "shared/ui/config.public";
import LocalizationProvider from "shared/ui/locales/localization-provider";
// ----------------------------------------------------------------------
import ThemeProvider from "shared/ui/theme";
import { queryClient } from "@/src/utils/query.utils";

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};
export default function RootTemplate({
  children
}: Props) {
  return <PlausibleProvider trackLocalhost={false} domain={publicConfig.host} data-sentry-element="PlausibleProvider" data-sentry-component="RootTemplate" data-sentry-source-file="template.tsx">
      <QueryClientProvider client={queryClient} data-sentry-element="QueryClientProvider" data-sentry-source-file="template.tsx">
        <AuthProvider data-sentry-element="AuthProvider" data-sentry-source-file="template.tsx">
          <LocalizationProvider data-sentry-element="LocalizationProvider" data-sentry-source-file="template.tsx">
            <SettingsProvider data-sentry-element="SettingsProvider" data-sentry-source-file="template.tsx">
              <ThemeProvider data-sentry-element="ThemeProvider" data-sentry-source-file="template.tsx">
                <MotionLazy data-sentry-element="MotionLazy" data-sentry-source-file="template.tsx">
                  <SnackbarProvider data-sentry-element="SnackbarProvider" data-sentry-source-file="template.tsx">
                    <ProgressBar data-sentry-element="ProgressBar" data-sentry-source-file="template.tsx" />
                    {children}
                  </SnackbarProvider>
                </MotionLazy>
              </ThemeProvider>
            </SettingsProvider>
          </LocalizationProvider>
        </AuthProvider>
      </QueryClientProvider>
    </PlausibleProvider>;
}