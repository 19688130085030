"use client";

import CssBaseline from "@mui/material/CssBaseline";
import type { ThemeOptions } from "@mui/material/styles";
import { createTheme, ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import merge from "lodash/merge";
import React, { useMemo } from "react";
import { useSettingsContext } from "../components/settings";
import { customShadows } from "./custom-shadows";
import NextAppDirEmotionCacheProvider from "./next-emotion-cache";
import { createContrast } from "./options/contrast";
import { createPresets } from "./options/presets";
// options
import RTL from "./options/right-to-left";
import { componentsOverrides } from "./overrides";
// system
import { palette } from "./palette";
import { shadows } from "./shadows";
import { typography } from "./typography";

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};
export default function ThemeProvider({
  children
}: Props) {
  const settings = useSettingsContext();
  const presets = createPresets(settings.themeColorPresets);
  const contrast = createContrast(settings.themeContrast, settings.themeMode);
  const memoizedValue = useMemo(() => ({
    palette: {
      ...palette(settings.themeMode),
      ...presets.palette,
      ...contrast.palette
    },
    customShadows: {
      ...customShadows(settings.themeMode),
      ...presets.customShadows
    },
    direction: settings.themeDirection,
    shadows: shadows(settings.themeMode),
    shape: {
      borderRadius: 8
    },
    typography
  }), [settings.themeMode, settings.themeDirection, presets.palette, presets.customShadows, contrast.palette]);
  const theme = createTheme(memoizedValue as ThemeOptions);
  theme.components = merge(componentsOverrides(theme), contrast.components);
  return <NextAppDirEmotionCacheProvider options={{
    key: "css"
  }} data-sentry-element="NextAppDirEmotionCacheProvider" data-sentry-component="ThemeProvider" data-sentry-source-file="index.tsx">
      <MuiThemeProvider theme={theme} data-sentry-element="MuiThemeProvider" data-sentry-source-file="index.tsx">
        <RTL themeDirection={settings.themeDirection} data-sentry-element="RTL" data-sentry-source-file="index.tsx">
          <CssBaseline data-sentry-element="CssBaseline" data-sentry-source-file="index.tsx" />
          {children}
        </RTL>
      </MuiThemeProvider>
    </NextAppDirEmotionCacheProvider>;
}